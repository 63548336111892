import { navigate } from "gatsby";
import { useActions, useReduxState } from "re-reduced";
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import { QuerySearchTicketsArgs, SearchTicketsByEventAppQuery, TicketScanDirection } from "@graphql/sdk";
import actions from "@state/actions";
import * as selectors from "@state/selectors";
import { DeviceTicket } from "@state/types";
import { Button, TextInput } from "@sub";
import { isSearchTicketsByEventAppQuery, searchTickets } from "@util/api";
import {
  assets,
  colors,
  colorsRGB, getStatusColor, pages
} from "@util/constants";
import { getTicketTypeName, ticketHolderName } from "@util/helper";
import { Container, GridContainer, H3, P } from "@util/standard";
import { useDebounce } from 'usehooks-ts';
import ResponseModal from "./responseModal";
import { formatEventDate } from "@util/formatDate";

const Wrapper = styled(Container)`
  flex-direction: column;
  width: 90%;
  margin: 20px auto;
`;

const ScrollContainer = styled(Container)`
  overflow-y: scroll;
  height: 100vh;
`;

const Box = styled.div`
  margin: 5px 0 10px;
`;

const Text = styled(P)`
  line-height: 1.5;
  margin: 0;
`;

const SecondaryText = styled(P)`
  line-height: 1.3;
  margin: 0;
  color: ${colorsRGB.white(0.6)};
  font-size: 16px;
`;

const Card = styled("div")<{ backgroundColor?: string }>`
  background-color: ${({ backgroundColor }) => backgroundColor ?? colors.grey};
  padding: 15px;
  border-radius: 5px;
  border: 2px solid ${colorsRGB.white(0.1)};
`;

const SearchTicket = () => {
  const {
    organizationId,
    eventId,
    loggedIn,
    status,
    errorMsg,
    ticket,
    isOnline,
    searchedDeviceTickets,
  } = useReduxState({
    isOnline: selectors.getOnline,
    organizationId: selectors.getOrgId,
    eventId: selectors.getEventId,
    loggedIn: selectors.getLoggedIn,
    scanDirection: selectors.getScanDirection,
    status: selectors.getScannedTicketStatus,
    errorMsg: selectors.getScannedTicketErrorMsg,
    ticket: selectors.getScannedTicketTicket,
    mode: selectors.getMode,
    searchedDeviceTickets: selectors.getSearchDeviceTickets,
  });
  const {
    updateRoute,
    scanTicket,
    clearScanTicket,
    setErrorMessage,
    searchDeviceTickets,
  } = useActions(actions);
  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearchTerm = useDebounce<string>(searchTerm, 300);

  const [isLoading, setIsLoading] = useState(false);
  const [ticketResults, setTicketResults] = useState<DeviceTicket[] | undefined>();

  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (!loggedIn) {
      navigate(pages.login);
    }
  }, []);

  useEffect(() => {
    if (debouncedSearchTerm !== "") {
      searchForTickets(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  const checkTicket = (orgResults: SearchTicketsByEventAppQuery) => {
    if (isSearchTicketsByEventAppQuery(orgResults)) {
      const tickets = orgResults.searchTicketsByEventApp.tickets.map(ticket => ({
        ...ticket,
        scans: ticket.scans.map(scan => ({
          ...scan,
          // Transform the direction value into a TicketScanDirection enum value
          direction: scan.direction?.toUpperCase().replace("-","_"), 
        }))
      }));
      
      setTicketResults(tickets as DeviceTicket[]);
      setIsLoading(false);
    } else {
      setErrorMessage(
        "Error searching for tickets"
      );
    }
  };

  //if device tickets have been searched and returned then show them
  useEffect(() => {
    if (searchedDeviceTickets && !isOnline) {
      setTicketResults(searchedDeviceTickets as DeviceTicket[]);
      setIsLoading(false);
    }
  }, [searchedDeviceTickets]);

  const searchForTickets = async (search?: string) => {
    setTicketResults(undefined);

    if (search && search.length > 2) {
      setIsLoading(true);

      if (!isOnline) {
        searchDeviceTickets({
          eventId,
          q: search,
        } as QuerySearchTicketsArgs);
      } else {
        const orgResults: SearchTicketsByEventAppQuery = await searchTickets(
          organizationId!,
          eventId!,
          search
        );
        checkTicket(orgResults);
      }
    } else {
      setTicketResults(undefined);
      setIsLoading(false);
    }
  };

  const handleModalClose = () => {
    setModalVisible(false);
    clearScanTicket();
  };

  const handleManualCheckIn = (ticket: DeviceTicket, direction: TicketScanDirection) => {
    setModalVisible(true);
    scanTicket({ ticketNumber: ticket.ticketNumber, direction });
  };



  return (
    <Wrapper>

      <div style={{ position: "relative" }}>
        <TextInput
          placeholderText="Search tickets"
          onChange={(event) => setSearchTerm(event.target.value)}
          margin="0 0 16px 0"
          dimensions={{ width: "88%" }}
          autoFocus
          color="grey"
        />

        <img src={assets.close} onClick={() => {
          updateRoute("scanner");
          navigate(pages.home);
        }} style={{ position: "absolute", right: 16, top: 16 }} />
      </div>


      {isLoading && <H3>Searching tickets...</H3>}


      {!isLoading && <ScrollContainer flexDirection="column" overflow="hidden">
        {(ticketResults && ticketResults?.length <= 0) && <H3>No tickets found</H3>}

        <GridContainer
          width="100%"
          repeat={1}
          tabletRepeat={1}
          rowGap={25}
        >
          {ticketResults && ticketResults.length > 0 && (
            ticketResults?.map((ticket) => {
              return <TicketCard key={ticket.id} ticket={ticket} onCheckIn={() => handleManualCheckIn(ticket, TicketScanDirection.ScanIn)} onCheckOut={() => handleManualCheckIn(ticket, TicketScanDirection.ScanOut)} />;
            })
          )}

        </GridContainer>
      </ScrollContainer>}

      <ResponseModal
        status={status!}
        errorMsg={errorMsg}
        ticket={ticket}
        handleModalClose={handleModalClose}
        modalVisible={modalVisible}
      />
    </Wrapper>
  );
};

export default SearchTicket;


function TicketCard({ ticket, onCheckIn, onCheckOut }: { ticket: DeviceTicket, onCheckIn: () => void, onCheckOut: () => void }) {
  
  const scannedIn = ticket.scans.sort((a,b ) => new Date(a.scannedAt).getTime() - new Date(b.scannedAt).getTime()).find(scan => scan.direction === TicketScanDirection.ScanIn);
  
  const statusColor = getStatusColor("success"); 
  
  const name = ticketHolderName(ticket);
  const ticketTypeName = getTicketTypeName(ticket);

  return (
    <Card backgroundColor={scannedIn ? statusColor : undefined}>  

      {scannedIn && <SecondaryText>Ticket scanned in at {formatEventDate(new Date(scannedIn.scannedAt), "datetime")}</SecondaryText>}
      <Text>{name}</Text>
      <Text>{ticket?.isEventAddon ? ticket?.eventAddonName : ticketTypeName}</Text>

      <Box>
        <SecondaryText>{ticket.buyerInformation?.email}</SecondaryText>
        <SecondaryText>Order # {ticket.orderNumber}</SecondaryText>
        <SecondaryText>QR Code # {ticket.ticketNumber}</SecondaryText>
      </Box>

      <Container flexDirection="row" justifyContent="space-between">

        <Button
          theme="black"
          text="CHECK IN"
          fontSize={14}
          padding="16px 0"
          onMouseDown={onCheckIn}
          width="100%"
          disableHoverEffect={true}
          margin="0 8px 0 0"
        />
        <Button
          theme="black"
          text="CHECK OUT"
          padding="16px 0"
          fontSize={14}
          onMouseDown={onCheckOut}
          width="100%"
          disableHoverEffect={true}
        />
      </Container>
    </Card>
  );
};