import { useReduxState } from "re-reduced";
import React from "react";
import styled from "styled-components";

import * as selectors from "@state/selectors";
import { DeviceTicket, RequestStatus } from "@state/types";
import { Button, Loading, Modal } from "@sub";
import { colorsRGB, getStatusColor } from "@util/constants";
import { getTicketTypeName, ticketHolderName, TIMEOUT_MSG } from "@util/helper";
import { Container, GridContainer, P } from "@util/standard";
import { SCAN_STATUS } from "@util/types";

const ModalContainer = styled(Container)`
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Title = styled(P)`
  color: ${colorsRGB.white(0.5)};
  margin: 5px 0;
`;

const statusText = {
  success: "Valid",
  error: "Invalid",
  alreadyScanned: "Already scanned",
  idle: "",
  issue: "Issue",
  wrongGate: "Wrong Gate",
};

interface Props {
  status: SCAN_STATUS;
  errorMsg: string | undefined;
  ticket: DeviceTicket | undefined;
  handleModalClose: () => void;
  modalVisible: boolean;
}

const propOrDefault = (prop: string | undefined, _default: string = "-") =>
  prop ? prop : _default;

const ResponseModal = (props: Props) => {
  const { ticket, errorMsg, status, handleModalClose, modalVisible } = props;

  const statusColor = getStatusColor(status!);
  const name = ticketHolderName(ticket);
  const ticketTypeName = getTicketTypeName(ticket);
  const orderNumber = ticket?.orderNumber as string;

  const { requestStatus } = useReduxState({
    requestStatus: selectors.getRequestStatus,
  });

  const Info = ({
    title,
    info,
  }: {
    title: string;
    info: string | undefined;
  }) => (
    <Container flexDirection="column">
      <Title>{title.toUpperCase()}</Title>
      <P noMargin>{propOrDefault(info)}</P>
    </Container>
  );

  return (
    <Modal
      visible={modalVisible}
      onClose={handleModalClose}
      maskBackgroundColor={status === "idle" ? "black" : statusColor}
    >
      <ModalContainer>
        {status && (
          <P
            margin="0"
            color={statusColor}
            fontSize={30}
            fontFamily="bioSansBold"
          >{`${statusText[status].toUpperCase()}`}</P>
        )}
        {errorMsg && (
          <P
            color={statusColor}
            fontSize={20}
            fontFamily="bioSansBold"
            margin="10px 0 0 0"
          >{`${errorMsg}`}</P>
        )}
        {ticket ? (
          <div>
            {name && <P fontSize={25}>{name}</P>}
            <GridContainer
              repeat={1}
              mobileRepeat={1}
              columnGap={10}
              rowGap={15}
            >
              {ticket?.event && (
                <Info title="Event" info={ticket?.event.title} />
              )}

              {!ticket?.isEventAddon && (
                <Info title={"Ticket type"} info={ticketTypeName} />
              )}
              {ticket?.isEventAddon && (
                <Info title={"Addon type"} info={ticket.eventAddonName ?? ""} />
              )}
              <Info title="Order number" info={orderNumber} />
              <Info title="QR Code" info={ticket?.ticketNumber} />
            </GridContainer>
          </div>
        ) : (
          <Container
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
          >
            {(!errorMsg || errorMsg === TIMEOUT_MSG) && <Loading />}
          </Container>
        )}
        {requestStatus !== RequestStatus.Pending && (
          <Button
            theme="inverse"
            text="CONTINUE"
            onClick={handleModalClose}
            color={statusColor}
            borderColor={statusColor}
          />
        )}
      </ModalContainer>
    </Modal>
  );
};

export default ResponseModal;
